<template>
  <v-row class="justify-md-space-around justify-center align-center">
    <v-col class="d-none d-md-flex" cols="4">
      <v-img  src="../assets/account.svg"></v-img>
    </v-col>
    <v-col xs="12" sm="12" md="6">
      <v-card class="elevation-12">
        <v-toolbar color="white" light flat>
          <v-toolbar-title>Création d'un compte</v-toolbar-title>
        </v-toolbar>
        <v-col>
          <FormAlert :info="{alert}"></FormAlert>
        </v-col>
        <v-form ref="form" v-on:submit.prevent v-model="valid">
          <v-card-text>
            <v-text-field
            label="Prénom et nom"
            name="name"
            prepend-icon="mdi-account"
            type="text"
            :counter="64"
            :rules="this.rules.name"
            v-model="user.name"/>
            <v-text-field
            label="Email"
            name="email"
            prepend-icon="mdi-at"
            type="text"
            v-model="user.email"
            :rules="this.rules.email"
            />
            <v-text-field
            id="password"
            label="Mot de passe"
            name="password"
            prepend-icon="mdi-lock"
            :append-icon="password.visible ? 'mdi-eye' : 'mdi-eye-off'"
            :type="password.visible ? 'text' : 'password'"
            @click:append="password.visible = !password.visible"
            v-model="user.password"
            :rules="this.rules.password"
            >
          </v-text-field>

        </v-card-text>
        <v-card-actions>
          <v-btn small to="/login" color="secondary" text>
            J'ai déjà un compte
          </v-btn>
          <v-spacer/>
          <v-btn type="submit" :disabled="!valid" color="primary" @click="submit()" outlined>
            Créer
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-col>
</v-row>
</template>

<script>

import MixinUsers from '@/mixins/users';
import FormAlert from '@/components/common/forms/Alert.vue';

export default {
  name: 'Signup',
  mixins: [MixinUsers],
  components: { FormAlert },
  data: () => ({
    valid: true,
    user: { name: '', email: '', password: '' },
  }),

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.createUser(this.user);
      }
    },
  },
};
</script>
