<template>
  <v-alert v-show="info.alert.visible" outlined
  :type="info.alert.type" transition="scale-transition" dense>
    <small>{{ info.alert.msg }}</small>
  </v-alert>
</template>

<script>
export default {
  name: 'FormAlert',
  props: {
    info: Object,
  },
};
</script>
